<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="10">
        <v-card
          elevation="4"
          outlined
          color="secondary"
          min-height="50vh"
          dark
          v-if="cardType === '0'"
        >

          <v-card-title class="text-success">
            {{$ml.get('getq')}}
          </v-card-title>
          <v-card-subtitle class="text-subtitle">
            {{$ml.get('create')}}
          </v-card-subtitle>
          
          <v-card-text class="text-text">
            <p v-text="$ml.get('textcreate')" ></p>
            <p v-text="$ml.get('textcreate2')"></p>
          </v-card-text>
          <v-row justify="center" align="center">
            <v-col cols="auto">
              <a><v-btn color="success" @click="cardType='1'">{{$ml.get('signup')}}</v-btn></a>
            </v-col>
          </v-row>
        </v-card>


        <v-card
          elevation="4"
          outlined
          color="secondary"
          min-height="50vh"
          dark
          v-if="cardType === '1'"
        >


          <v-card-title class="text-success">
            {{$ml.get('getq')}}
          </v-card-title>
          <v-card-subtitle class="text-subtitle">
            {{$ml.get('signup')}}
          </v-card-subtitle>
          <v-card-text class="text-text">
            <p v-html="$ml.get('signuptxt')"></p>

            <v-row justify="center">
              <v-col md="10" cols="12">          
                <v-form
                    ref="form"
                    v-model="valid"
                    
                  >
                    <v-row>
                      <v-col md="6" cols="12">
                        <v-text-field
                            v-model="firstName"
                            :counter="50"
                            :rules="firstNameRules"
                            :label="$ml.get('firstn')"
                            outlined
                            required
                            background-color="primary"
                            color="white"
                          ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          v-model="name"
                          :counter="50"
                          :rules="nameRules"
                          :label="$ml.get('lastn')"
                          outlined
                          required
                          background-color="primary"
                          color="white"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-text-field
                      v-model="enterprise"
                      :counter="50"
                      :label="$ml.get('company')"
                      outlined
                      background-color="primary"
                      color="white"
                    ></v-text-field>

                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail*"
                      outlined
                      required
                      background-color="primary"
                      color="white"
                    ></v-text-field>
                    <v-text-field
                      v-model="phone"
                      :label="$ml.get('phone')"
                      outlined
                      background-color="primary"
                      color="white"
                    ></v-text-field>
                    <!--<v-checkbox
                      @click="product_computed"
                      v-model="platform"
                      :label="$ml.get('platformcheck')"
                    ></v-checkbox>-->
                    <!--
                    <v-select
                      v-model="product"
                      :items="products"
                      item-text="text"
                      item-value="value" 
                      :rules="[v => !!v || 'Item is required']"
                      :label="$ml.get('product')"
                      required
                      outlined
                      background-color="primary"
                      color="white"
                    ></v-select>-->
                    <p v-text="$ml.get('required')"></p>
                    <v-row justify="center" v-if="registerLoading">
                      <v-col cols="auto">
                      <v-progress-circular
                        indeterminate
                        color="red"
                      ></v-progress-circular>
                      </v-col>
                    </v-row>
                    <v-row justify="space-around">
                      <v-col cols="auto">
                        <v-btn
                          :disabled="!valid"
                          color="success"
                          @click="validate"
                        >
                          {{$ml.get('validate')}}
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          color="warning"
                          @click="reset"
                        >
                          {{$ml.get('reset')}}
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-form>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>

        <v-card
          elevation="4"
          outlined
          color="secondary"
          min-height="50vh"
          dark
          v-if="cardType === '2'"
        >
          <v-card-title class="text-success">
            QUANTEEC
          </v-card-title>
          <v-card-subtitle class="text-subtitle">
            {{$ml.get('okaccount')}}
          </v-card-subtitle>
          <v-card-text class="text-text">
            <p> {{$ml.get('emailacc')}} <span style="color:white">{{email}}</span></p>

            <!--<p>{{$ml.get('url')}}: <a :href="result.platformURL" target="_blank" style="color: white;">{{result.platformURL}}</a></p>-->
            <!--
            <p>{{$ml.get('username')}} <strong>{{result.username}}</strong></p>
            <p>{{$ml.get('passwd')}} <strong>{{result.password}}</strong></p>
            -->
          </v-card-text>
        </v-card>


      </v-col>
    </v-row>

   <v-dialog
      v-model="isError">
      <v-card
        dark
      >
        <v-card-title>
          {{$ml.get('erroracc')}}
        </v-card-title>
        <v-card-text>
          {{errorText}}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="isError = false" color="warning">{{$ml.get('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import {BASE_URL} from '@/services/base-url';
  export default {
    name: 'HelloWorld',
    data: () => ({
      valid:false,
      isError : false,
      registerLoading: false,
      cardType: '1',
      name: '',
      firstName: '',
      enterprise: '',
      email: '',
      phone: '',
      platform: false,
      product: 'plugin',
      products: [
        {text: 'Quanteec Platform', value: 'platform'},
        {text: 'Quanteec Plug-In', value: 'plugin'}
      ],
      nameRules: [
        v => !!v || 'Name is required'
      ],
      firstNameRules: [
        v => !!v || 'First name is required'
      ],
      phoneRules: [
        v => !!v || 'Phone is required',
        v => v.length >= 10 || 'Phone is too short',
        v=> /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(v) || 'Phone must be valid'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      result: {}

    }),
    mounted() {
      let inputProduct = this.$route.params['product'];
      this.reset();
      if (inputProduct === 'plugin' || inputProduct === 'platform') {
        this.product = inputProduct;
      }
      
    },
    computed: {
      product_computed() {
        if (this.platform)
          this.product = "platform";
        else
          this.product = "plugin";
      }
    },
    methods: {
      validate() {
        let data = {
            contactFirstName: this.firstName,
            contactName: this.name,
            email: this.email,
            phone: this.phone,
            product: this.product
        }
        if (!!this.enterprise) {
          data.enterpriseName = this.enterprise;
        }
        let req = {
          url: `${BASE_URL}/api/clients`,
          method: 'post',
          data: data
        }
        this.registerLoading = true;
        axios(req).then((response) => {
          this.result = response.data;
          this.cardType = "2";
          this.registerLoading = false;
        }).catch(err => {
           if(err.response) {
            this.errorText = err.response.data;
          } else {
            this.errorText = "Please retry later or contact QUANTEEC at: support@quanteec.com";
          }
          this.isError = true;
          this.registerLoading = false;
        })

      },
      reset() {
        this.name = '';
        this.firstName = '';
        this.enterprise = '';
        this.email = '';
        this.phone = '';
        this.product = 'plugin';
        this.platform = false;
        this.result = {};
      }
    }
  }
</script>

<style scoped>
.text-success {
  color: #14BF98;
}
.text-subtitle {
  color: #fff !important;
  font: 700 1.75rem/2.125rem "Montserrat", sans-serif;
}

.text-text {
  color: #dfe5ec;
  font: 400 1rem/1.5625rem "Open Sans", sans-serif;
}

</style>
