<template>
  <Register/>
</template>

<script>
  import Register from '../components/Register'

  export default {
    name: 'RegisterPage',

    components: {
      Register,
    },
  }
</script>